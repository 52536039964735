<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/WechatIMG6210.png" style="width: 200px;">

    <HelloWorld msg="让十万音乐人财富自由"/>



    <div style="margin-top:30%">
        <h2>《指尖乐手》—— 连接你我，探索音乐世界</h2>
        <div>《指尖乐手》是一款专为音乐人和乐手打造的社交平台应用，旨在连接全球各地的音乐爱好者，为他们提供组队合作、寻找演出机会以及分享音乐经验的平台。</div>
    </div>
    <div style="width: 100%;">
      <div v-if="isMobile==false">
        <div style="margin-top: 20px;display:flex;justify-content: center; align-items:center;">
          <div style="display: flex;flex-direction: row; justify-content: center;">
            <div v-for="item in mobel_app_items">
              <el-card class="card">
                <el-image :src="item.url"></el-image>
                <span>{{ item.name }}</span>
              </el-card>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isMobile==true" style="overflow: scroll;">
        <div style="width: 1000px; margin-top: 20px;display:flex;justify-content: left; align-items:center;">
          <div style="display: flex;flex-direction: row; justify-content: center;">
            <div v-for="item in mobel_app_items">
              <el-card class="card">
                <el-image :src="item.url"></el-image>
                <span>{{ item.name }}</span>
              </el-card>
            </div>
          </div>
        </div>  
      </div>
    </div>

    <el-container style="margin-top:30%;">
      <el-main>
        <h2>《指尖律动scrm》—— 专为小型和中型企业设计的客户关系管理软件</h2>
        
        <div>SCRM轻量化系统是一款专为小型和中型企业设计的客户关系管理软件。它致力于帮助企业管理和维护与客户之间的关系，以提升客户满意度、增加销售额和促进业务增长。</div>
        
        <el-link :underline="false" href="https://www.yueshouba.com" target="_blank" style="text-decoration:underline;font-size: 20px;color: cornflowerblue;">申请试用</el-link>
      </el-main>

      <el-main>
        <h2>客户管理</h2>
        
        <div> 我们的系统提供了完善的客户管理功能，您可以轻松地记录客户信息、交易历史、沟通记录等，帮助您更好地了解客户需求，并实施个性化的服务。</div>
        
        <!-- <el-link :underline="false" href="https://www.yueshouba.com" target="_blank" style="text-decoration:underline;font-size: 20px;color: cornflowerblue;">申请试用</el-link> -->

        <div  style=" margin-top: 40px; width: 100%;display: flex;justify-content: center;">
          <el-card style="width: 100%">
            <el-image src="https://systeminfo1.facetechmedia.com/scrmdesc.png" class="image"></el-image>
          </el-card>
        </div>
      </el-main>
      
      <el-main>
        <h2>客户标签管理</h2>
        
        <div> 根据客户的行为、偏好等特征，您可以创建自定义的客户标签，以便更好地分类和管理客户群体，并针对不同标签的客户实施有针对性的营销策略，我们提供各种自动化标签关联方式</div>
        
        <div  style=" margin-top: 40px; width: 100%;display: flex;justify-content: center;">
          <el-card style="width: 100%">
            <el-image src="https://systeminfo1.facetechmedia.com/biaoqianguanli.png" class="image"></el-image>
          </el-card>
        </div>
      </el-main>
      
      <el-main>
        <h2>消息群发</h2>
        
        <div>通过我们的系统，您可以轻松地进行消息群发，向客户发送促销活动、新产品推介等信息，实现更高效的营销</div>
        
        <div  style=" margin-top: 40px; width: 100%;display: flex;justify-content: center;">
          <el-card style="width: 100%">
            <el-image src="https://systeminfo1.facetechmedia.com/qunfaguanli.png" class="image"></el-image>
          </el-card>
        </div>
      </el-main>

      <el-main>
        <h2>获客链接管理</h2>
        
        <div>我们的系统支持管理获客链接，帮助您跟踪不同渠道的客户来源，从而优化营销策略和资源投入</div>
        
        <div  style=" margin-top: 40px; width: 100%;display: flex;justify-content: center;">
          <el-card style="width: 100%">
            <el-image src="https://systeminfo1.facetechmedia.com/hklj.png" class="image"></el-image>
          </el-card>
        </div>
      </el-main>

      <el-main>
        <h2>直播管理</h2>
        
        <div>通过与抖音、快手等知名直播平台的数据打通，我们的系统可以帮助您管理直播活动，吸引更多粉丝，并提升销售效果</div>
        
        <div  style=" margin-top: 40px; width: 100%;display: flex;justify-content: center;">
          <el-card style="width: 100%">
            <el-image src="https://systeminfo1.facetechmedia.com/zzj.png" class="image"></el-image>
          </el-card>
        </div>
      </el-main>

      <el-main>
        <h2>商品订单管理</h2>
        
        <div>我们的系统集成了完整的电商功能，包括商品管理、订单管理、支付管理等，让您能够轻松实现在线销售，并提供流畅的购物体验给客户</div>
        
        <div  style=" margin-top: 40px; width: 100%;display: flex;justify-content: center;">
          <el-card style="width: 100%">
            <el-image src="https://systeminfo1.facetechmedia.com/gkxy.png" class="image"></el-image>
          </el-card>
        </div>
      </el-main>

      <el-main>
        <h2>企业组织架构管理</h2>
        
        <div>我们的系统还提供企业组织架构管理功能，帮助您更好地组织和管理团队，提升协作效率。</div>
        
        <div  style=" margin-top: 40px; width: 100%;display: flex;justify-content: center;">
          <el-card style="width: 100%">
            <el-image src="https://systeminfo1.facetechmedia.com/zjjg.png" class="image"></el-image>
          </el-card>
        </div>
      </el-main>


      <el-footer>

        <div style="margin-top: 20px; display: flex;flex-direction: row;justify-content: center;align-items: center;">
          <div v-for="item in logo_icons" style="padding-inline: 8px;">
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <el-button type="text">
                <el-image :src="item.url" class="logo"></el-image>
              </el-button>
              
            </div>
          </div>
        </div>
        <br>
        <span style="color: grey;">Copyright © 2023-2024 指尖律动   yueshouba.com All Rights Reserved.</span>

        <el-divider content-position="center">备案号:京ICP备2023010764号-1</el-divider>
        <br>
        <br>
        <br>
      </el-footer>
    </el-container>
      
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import { getCompressionQuality, compressImage } from '@/utils/compressionUtils.js';


const mobel_app_items = [
  {url:'https://systeminfo1.facetechmedia.com/IMG_1348.PNG',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/IMG_1347.PNG',name:'发布需求'},
  {url:'https://systeminfo1.facetechmedia.com/IMG_1346.PNG',name:'社交平台'},
]


const logo_icons =[
  {url:'https://systeminfo1.facetechmedia.com/logosbilibili.png',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/logosfacebook.png',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/logosinstagram.png',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/logosqqmusic.png',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/logostiktok.png',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/logosweibo.png',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/logoswyy.png',name:'个人介绍'},
  {url:'https://systeminfo1.facetechmedia.com/logosxhs.png',name:'个人介绍'},
]

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  created() {
    this._isMobile()
    this.P_name = this.IsWeixinOrAlipay()

    var url = window.location.href ;  
    console.log("url = ",url)
  
    var cs = url.split('?')[1];
    var cs_arr = cs.split('&');
    var cs={};
    for(var i=0;i<cs_arr.length;i++){
      cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
    }

    this.Form.Hotel_name = decodeURIComponent(cs.Hotel_name);
    this.Form.Device_no =  cs.Id;

    this.reportScaned(this.Form.Device_no)
  },
  mounted() {
    this._isMobile()
  },
  data() {
    return {
      Form:{
        P_name:'',
        Name:'',
        IdNo:'',
        Nation_code:'',
        Address:'',
        Areas_code:'',
        Device_no:'',
      },
      Photo:{
        token:'bjcpwin',
        photo:'',
      },
      mobel_app_items:mobel_app_items,
      logo_icons:logo_icons,
      isMobile:false,
    }
  },
  methods: {
    async postPhtot(Photo){
      var url = 'http://139.198.15.16:8088/api/face/IDCardOCR'
      const rsp = await this.$http.post(url,Photo,{headers: {'Content-Type': 'application/json'}})
      return rsp
    },
    beforeUpload(file) {
      const imageConversion = require("image-conversion");
      console.log('压缩前'+(file.size / 1024)+"K");
      this.loading = true;
      imageConversion.compressAccurately(file, 0.3).then(res => {
        this.handleChange(res)
        
      })
    },
    async handleChange(file, fileList) {
      try {

        let file_obj = {raw:file}
        const content = await this.handleFileChange(file_obj);
        
        console.log("content = ",content)

        // 在这里可以使用文件内容进行一些操作
        

        // let  verify = this.vertifyFileSizeFormat(file)

        // if (!verify) {
        //   return
        // }

        this.postPhtot({photo:content.Resource_base64,token:'bjcpwin'}).then(res=>{
            
            var rsp = res.data
            if (rsp.Code == 0) {

              console.log(rsp.Data.name)

              this.Form.Name = rsp.Data.name
              this.Form.IdNo = rsp.Data.idNo
              this.Form.Nation_code = rsp.Data.ethnicity
              this.Form.Address = rsp.Data.domicile
              this.Form.Areas_code = rsp.Data.idNo.slice(0,4)

              console.log("this.Form = ",this.Form)

            }
        })
      } catch (error) {
        console.error('Error reading the file:', error);
      }
    },

    handleFileChange(file,fileList) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function() {
          const base64data = reader.result
          var rspobj = {}
          rspobj.Resource_base64 = base64data.split(',')[1]
          rspobj.Size = file.size

          resolve(rspobj);
        };
        
        reader.onerror = function(error) {
          reject(error);
        };

        reader.readAsDataURL(file.raw)
      })
    },
    // 对文件的大小的判断及类型
    vertifyFileSizeFormat(file) {


      const arr = file.name.split('.')
      const filename = arr[0]
      const format = arr[1]

      console.log("file.size = ",file.size)
      console.log(format)

      if (format !='png'&& format !='jpg'&& format !='jpeg') {

        this.$notify({
          title: '上传失败',
          message: '图片格式仅支持png和jpg格式',
          type: 'error',
          duration: 2000,
        })

        return false
      }
      
      if (format) {
        console.log(filename)
        console.log(format)
        // this.reload()
      } else {
        this.$notify({
          title: '上传失败',
          message: '请上传正确后缀格式文件',
          type: 'error',
          duration: 2000,
        })
        return false
      }
      const isLt2M = file.size / 1024.0 < 200

      if (!isLt2M) {
        this.$notify({
          title: '上传失败',
          message: '上传模板大小不能超过 200k!',
          type: 'error',
          duration: 2000,
        })
        return false
      }
      return true
    },     
    reportScaned(Device_no) {
      var url = 'http://139.198.15.16:9998/v0.1/idcardinfo/SetQrCodeState'

      this.$http.post(url, {Device_no}, {headers: {'Content-Type': 'application/json'}}).then((data) => {
        console.log(data)
      })

    },
    confirm() {
      console.log(this.Form)
      var url = 'http://139.198.15.16:9998/v0.1/idcardinfo/UploadIdcardDeviceInfoWebNoSm4'

      this.$http.post(url, this.Form, {headers: {'Content-Type': 'application/json'}}).then((data) => {
        console.log(data)

        this.loading = false
        this.$notify({title: '成功', message: '保存成功', type: 'success'})
      }).catch(err => {
        this.$message.error(err + '')
      })


    },

    IsWeixinOrAlipay() {

      var ua = window.navigator.userAgent;
      console.log("ua = ",ua)

      //判断是不是微信
      if ( ua.indexOf("MicroMessenger") > 0 ) {  
          return "WeiXIN";  
      }

      //判断是不是支付宝
      if (ua.indexOf("AlipayClient") > 0) {
          return "Alipay";  
      }
    },
    _isMobile() {
	    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	    if (flag) {
        this.isMobile = true 
      } else {
        this.isMobile = false
      }
       
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.logo {
  width: 24px;
  height: 24px;
}

.names {
  font-size: 12px;
  font-weight: 200;
}
.image {
  width: 85%
  /* height: 100%; */
    /* display: block; */
}
.text {
  font-size: 24px;
  font-weight: 800;
}
.card {
  margin: 10px;
  width: 280px;
  /* height: 867px; */
}


</style>
