<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <h3>指尖乐手社交</h3>
    <ul>
      <li><a href="https://www.yueshouba.com" target="_blank" rel="noopener">项目介绍</a></li>
    </ul>
    <h3>企业scrm系统</h3>
    <ul>
      <li><a href="https://www.yueshouba.com" target="_blank" rel="noopener">功能介绍</a></li>
      <li><a href="https://www.yueshouba.com" target="_blank" rel="noopener">Docs</a></li>
      <li><a href="https://www.yueshouba.com" target="_blank" rel="noopener">立即试用</a></li>
    </ul>
    

    <h3>与我们联系</h3>  
    <br>
    <a href="https://www.yueshouba.com" target="_blank" rel="noopener">电子邮箱</a>
  
    <p>
      企业微信
    </p>
    <el-image src="https://systeminfo1.facetechmedia.com/WechatIMG280.png"></el-image>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgb(226, 115, 4);
}
</style>
